import { type Action, createReducer, on } from "@ngrx/store";
import type { CurrentServiceCenter } from "./shared.models";
import type { Lpn, Resume } from "@cg/shared";
import * as SharedActions from "./shared.actions";

export const SHARED_FEATURE_KEY = "shared";

export interface State {
  currentServiceCenter: CurrentServiceCenter;
  lpn: Lpn;
  resumeResponse: Resume;
}

export interface SharedPartialState {
  readonly [SHARED_FEATURE_KEY]: State;
}

export const initialState: State = {
  currentServiceCenter: null,
  lpn: null,
  resumeResponse: null
};

const sharedReducer = createReducer(
  initialState,
  on(SharedActions.setCurrentServiceCenter, (state: State, { payload }: { payload: CurrentServiceCenter }) => ({
    ...state,
    currentServiceCenter: payload
  })),
  on(SharedActions.setLicensePlateNumber, (state: State, { payload }: { payload: Lpn }) => ({
    ...state,
    lpn: payload
  })),
  on(SharedActions.setResumeResponse, (state: State, { payload }: { payload: Resume }) => ({
    ...state,
    resumeResponse: payload
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return sharedReducer(state, action);
}
