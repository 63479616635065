import { createAction, props } from "@ngrx/store";
import type { CurrentServiceCenter } from "./shared.models";
import type { Lpn, Resume } from "@cg/shared";

export const setCurrentServiceCenter = createAction(
  "[Shared] Set Current Service Center",
  props<{ payload: CurrentServiceCenter }>()
);

export const setLicensePlateNumber = createAction("[Shared] Set License Plate Number", props<{ payload: Lpn }>());

export const setResumeResponse = createAction("[Shared] Set Resume Response", props<{ payload: Resume }>());
