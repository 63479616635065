const uniqBy = (array: unknown[], property: string | ((...args: unknown[]) => unknown)) => {
  const compare =
    typeof property === "function"
      ? property
      : (left: unknown[], right: unknown[]) => {
          if (!left[property] || !right[property]) {
            return false;
          }

          return left[property] === right[property];
        };

  const newArray = [];

  array.forEach((right: unknown) => {
    const i = newArray.findIndex((left: unknown) => compare(left, right));

    if (i === -1) {
      newArray.push(right);
    }
  });

  return newArray;
};

export { uniqBy };
