import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import * as SharedActions from "./shared.actions";
@Injectable()
export class SharedEffects {
  // todo: side effect to navigate when reducer has run and state is ready to be transferred via init services

  public constructor(
    private actions$: Actions,
    private router: Router
  ) {}

  public setCurrentServiceCenter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.setCurrentServiceCenter),
        tap(() => {
          this.router.navigateByUrl("/olb");
        })
      ),
    { dispatch: false }
  );
}
