import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { isNotEmpty } from "@cg/utils";
import { Lpn, ProcessId, ProcessMetadata, Resume } from "@cg/shared";
import { ResumeProcessData } from "../interfaces/resume-process-data.interface";
import * as SharedActions from "./shared.actions";
import { CurrentServiceCenter } from "./shared.models";
import * as fromShared from "./shared.reducer";
import * as SharedSelectors from "./shared.selectors";

@Injectable({
  providedIn: "root"
})
export class SharedFacade {
  public currentServiceCenter$ = this.store.pipe(select(SharedSelectors.getCurrentServiceCenter));
  public lpn$ = this.store.pipe(select(SharedSelectors.getLpn));
  public resumeResponse$ = this.store.pipe(select(SharedSelectors.getResumeResponse));

  public constructor(private store: Store<fromShared.SharedPartialState>) {}

  public setCurrentServiceCenter(currentServiceCenter: CurrentServiceCenter): void {
    this.store.dispatch(SharedActions.setCurrentServiceCenter({ payload: currentServiceCenter }));
  }

  public setLicensePlateNumber(lpn: Lpn) {
    this.store.dispatch(SharedActions.setLicensePlateNumber({ payload: lpn }));
  }

  public setResumeResponse(resumeResponse: Resume) {
    this.store.dispatch(SharedActions.setResumeResponse({ payload: resumeResponse }));
  }

  public getResumeByProcess(processId: ProcessId): Observable<ResumeProcessData | null> {
    return this.resumeResponse$.pipe(
      filter(isNotEmpty),
      map((resumeResponse: Resume) => {
        const state =
          typeof resumeResponse.state !== "string"
            ? resumeResponse.state?.processMetaData?.find(
                (processState: ProcessMetadata) => processState.id === processId
              )
            : null;

        if (state) {
          return { ...resumeResponse, selectedState: state };
        }

        return null;
      })
    );
  }
}
